import React, { useState, useEffect } from 'react';
import { doc, updateDoc, deleteDoc, addDoc, collection } from "firebase/firestore";
import { db } from '../firebase'; // Adjust the import as needed
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'react-toastify/dist/ReactToastify.css';

// Helper function to convert Firestore timestamps to date strings
const convertTimestampToString = (timestamp) => {
  if (timestamp && timestamp.seconds) {
    const date = new Date(timestamp.seconds * 1000);
    return date.toISOString().split('T')[0]; // Format to YYYY-MM-DD
  }
  return '';
};

const EventsAccord = ({ events }) => {
  const [eventsList, setEventsList] = useState([]);
  const [editEvent, setEditEvent] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newEventData, setNewEventData] = useState({ title: '', start: '', end: '', allDay: false, HostName: '' });

  useEffect(() => {
    if (events && events.length > 0) {
      // Convert timestamps to date strings
      const convertedEvents = events.map(event => ({
        ...event,
        start: convertTimestampToString(event.start),
        end: convertTimestampToString(event.end),
      }));
      console.log('events: ', convertedEvents);
      setEventsList(convertedEvents);
    }
  }, [events]);

  const handleEdit = (event) => {
    setEditEvent({ ...event });
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'events', id));
      const updatedEvents = eventsList.filter(event => event.id !== id);
      setEventsList(updatedEvents);
      toast.success('SUCCESS: Event deleted!', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error('Error deleting event:', error);
      toast.error('Error deleting event. Please try again.', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleSaveEdit = async () => {
    try {
      const updatedEvent = { ...editEvent };
      await updateDoc(doc(db, 'events', editEvent.id), updatedEvent);
      const updatedEvents = eventsList.map(event => event.id === editEvent.id ? updatedEvent : event);
      setEventsList(updatedEvents);
      toast.success('SUCCESS: Event updated!', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setEditEvent(null);
    } catch (error) {
      console.error('Error saving edit:', error);
    }
  };

  const handleAddEvent = () => {
    setShowAddForm(true);
  };

  const handleCancelAdd = () => {
    setShowAddForm(false);
    setNewEventData({ title: '', start: '', end: '', allDay: false, HostName: '' });
  };

  const handleSaveAdd = async () => {
    try {
      const newEvent = { ...newEventData };
      const docRef = await addDoc(collection(db, 'events'), newEvent);
      const updatedEvents = [...eventsList, { ...newEvent, id: docRef.id }];
      setEventsList(updatedEvents);
      setShowAddForm(false);
      setNewEventData({ title: '', start: '', end: '', allDay: false, HostName: '' });
      toast.success('SUCCESS: New event added!', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error('Error saving new event:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditEvent(null);
  };

  return (
    <div className='eventsAccord'>
      <button style={{ marginBottom: '20px', float: 'right' }} onClick={handleAddEvent}>
        <FontAwesomeIcon icon="plus-circle" className="rewardsico" /> Add Event
      </button>
      {showAddForm && (
        <div className='newform'>
          <input
            type="text"
            value={newEventData.title}
            onChange={(e) => setNewEventData({ ...newEventData, title: e.target.value })}
            placeholder="Enter Title"
          />
          <input
            type="date"
            value={newEventData.start}
            onChange={(e) => setNewEventData({ ...newEventData, start: e.target.value })}
            placeholder="Start Date"
          />
          <input
            type="date"
            value={newEventData.end}
            onChange={(e) => setNewEventData({ ...newEventData, end: e.target.value })}
            placeholder="End Date"
          />
          <input
            type="checkbox"
            checked={newEventData.allDay}
            onChange={(e) => setNewEventData({ ...newEventData, allDay: e.target.checked })}
          /> All Day
          <input
            type="text"
            value={newEventData.HostName}
            onChange={(e) => setNewEventData({ ...newEventData, HostName: e.target.value })}
            placeholder="Enter Host Name"
          />
          <button onClick={handleSaveAdd}>Save</button>
          <button onClick={handleCancelAdd}>Cancel</button>
        </div>
      )}
      <table className="table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Start</th>
            <th>End</th>
            <th>All Day</th>
            <th>Host</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {eventsList.map((event, index) => (
            <tr key={index}>
              <td>{editEvent && editEvent.id === event.id ? (
                <input type="text" value={editEvent.title} onChange={(e) => setEditEvent({ ...editEvent, title: e.target.value })} />
              ) : event.title}</td>
              <td>{editEvent && editEvent.id === event.id ? (
                <input type="date" value={editEvent.start} onChange={(e) => setEditEvent({ ...editEvent, start: e.target.value })} />
              ) : event.start}</td>
              <td>{editEvent && editEvent.id === event.id ? (
                <input type="date" value={editEvent.end} onChange={(e) => setEditEvent({ ...editEvent, end: e.target.value })} />
              ) : event.end}</td>
              <td>{editEvent && editEvent.id === event.id ? (
                <input type="checkbox" checked={editEvent.allDay} onChange={(e) => setEditEvent({ ...editEvent, allDay: e.target.checked })} />
              ) : event.allDay ? 'Yes' : 'No'}</td>
              <td>{editEvent && editEvent.id === event.id ? (
                <input type="text" value={editEvent.HostName} onChange={(e) => setEditEvent({ ...editEvent, HostName: e.target.value })} />
              ) : event.HostName}</td>
              <td>
                {editEvent && editEvent.id === event.id ? (
                  <>
                    <button onClick={handleSaveEdit}>Save</button>
                    <button onClick={handleCancelEdit}>Cancel</button>
                  </>
                ) : (
                  <>
                    <button onClick={() => handleEdit(event)}>Edit</button>
                    <button onClick={() => handleDelete(event.id)}>Delete</button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default EventsAccord;
