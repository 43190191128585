import React, { useState, useEffect } from 'react';
import { Collapse, Card, CardBody, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { storage, ref, uploadBytes, getDownloadURL, db } from '../firebase';
import FileUpload from '../components/FileUpload';
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles
import 'react-toastify/dist/ReactToastify.css';

const InfoAccordion = () => {
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [curimage, setImage] = useState(null);
  const [aboutUsText, setAboutUsText] = useState(null);
  const [aboutUsImage, setAboutUsImage] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [rewardsText, setRewardsText] = useState(null);
  const [siteName, setSiteName] = useState(null);


useEffect(() => {
    const handleJawn = async () => {
        const siteSettingsDocRef = doc(db, "settings", "site");
        const siteSettingsDoc = await getDoc(siteSettingsDocRef);
        const siteSettingsData = siteSettingsDoc.data();

        setPrimaryColor(siteSettingsData.primaryColor);
        setSecondaryColor(siteSettingsData.secondaryColor);
        setImage(siteSettingsData.logoUrl);
        setLogoFile(siteSettingsData.logoUrl);
        setAboutUsText(siteSettingsData.aboutText);
        setAboutUsImage(siteSettingsData.aboutImage);
        setSiteName(siteSettingsData.siteName);
        setRewardsText(siteSettingsData.rewardsText);
    }
    handleJawn()
  }, []);

  const toggleAccordion = () => {
    setIsOpen(!isOpen); // Toggle the state
  };

  const handleUpdate = async () => {
    try {
      const gdbRef = doc(db, 'settings', 'site');
      await updateDoc(gdbRef, {
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        aboutText: aboutUsText,
        aboutImage: aboutUsImage,
        rewardsText: rewardsText,
        logoUrl: logoFile
      });
      console.log('Settings updated successfully!');
      toast.success('SUCCESS: Information Settings Updated!', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  const handleApiCall = async (imageUrl) => {
    try {
        // Update the settings document in Firebase
        const siteRef = doc(db, 'settings', 'site');
        await updateDoc(siteRef, { logoUrl: imageUrl });
    } catch (error) {
        console.error('Error updating logo URL:', error);
    }
  }  

  const handleFileChangeAbout = async (file) => {
        if (!file) {
            console.error('Error saving item: No file selected');
            return;
        }

        const storageRef = ref(storage, 'info/' + file.name);
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        setAboutUsImage(downloadURL);
    };

  const handleLogoChange = async (file) => {
        if (!file) {
            console.error('Error saving item: No file selected');
            return;
        }
        const storageRef = ref(storage, 'info/' + file.name);
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        setLogoFile(downloadURL);
    };

  return (
    <div className='subsub'>
    <Button className={`AccordionButton`} style={isOpen ? { backgroundColor: secondaryColor, color: "white" } : {}} onClick={toggleAccordion}>Info</Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
            <Form>
            <FormGroup>
                <Label for="secondaryColor">Company Name:</Label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Input
                    type="text"
                    name="siteName"
                    id="siteName"
                    value={siteName}
                    onChange={(e) => setSiteName(e.target.value)}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="primaryColor">Logo:</Label>
                {curimage && 
                 <img alt="current logo" className="currentlog" src={curimage} />
                }
               <FileUpload onUploadSuccess={handleLogoChange} />
               
              </FormGroup>
              <FormGroup>
                <Label for="primaryColor">Primary Color:</Label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: primaryColor,
                      marginRight: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '3px',
                    }}
                  ></div>
                  <Input
                    type="text"
                    name="primaryColor"
                    id="primaryColor"
                    value={primaryColor}
                    onChange={(e) => setPrimaryColor(e.target.value)}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="secondaryColor">Secondary Color:</Label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: secondaryColor,
                      marginRight: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '3px',
                    }}
                  ></div>
                  <Input
                    type="text"
                    name="secondaryColor"
                    id="secondaryColor"
                    value={secondaryColor}
                    onChange={(e) => setSecondaryColor(e.target.value)}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="secondaryColor">About us Text:</Label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <ReactQuill
                    value={aboutUsText}
                    onChange={setAboutUsText}
                    modules={{ toolbar: true }}
                    formats={['bold', 'italic', 'underline', 'link']}
                    placeholder="Enter text here..."
                />
                </div>
              </FormGroup>
              
              <FormGroup>
                <Label for="secondaryColor">About us Image:</Label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FileUpload onUploadSuccess={handleFileChangeAbout} />
                    {aboutUsImage && 
                        <img alt="current logo" style={{float: 'right'}} className="currentlog" src={aboutUsImage} />
                    }
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="secondaryColor">Rewards Copy:</Label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <ReactQuill
                    value={rewardsText}
                    onChange={setRewardsText}
                    modules={{ toolbar: true }}
                    formats={['bold', 'italic', 'underline', 'link']}
                    placeholder="Enter text here..."
                />
                </div>
              </FormGroup>
              {/* Add additional form fields here */}
              <Button className='update-bt' color="primary" onClick={handleUpdate}>Save</Button>
            </Form>
          </CardBody>
        </Card>
      </Collapse>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default InfoAccordion;
