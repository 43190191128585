import React, { useState, useEffect } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/ts-logo.jpg";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    const siteSettings = sessionStorage.getItem("siteSettings");
    if (siteSettings) {
      const settings = JSON.parse(siteSettings);
      setPrimaryColor(settings.primaryColor);
      setSecondaryColor(settings.secondaryColor);
      setLogoUrl(settings.logoUrl)
    }
  }, []);

  return (
    <div className="nav-container">
      <Navbar expand="md">
        <Container>
          <div className="logo-hold">
            <a href="/">
              { logoUrl && 
                <img alt="logo" className="mb-3 app-logo updates" src={logoUrl} />
              }
            </a>
          </div>
          {/* <NavbarToggler onClick={toggle} />
           */}
           <NavbarToggler onClick={toggle} style={{ borderColor: 'black', color: 'black' }} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/about"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  About us
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/events"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Events
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/menu"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Menu
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/locations"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Locations
                </NavLink>
              </NavItem>
            </Nav>
            <a href="https://taco-shop-rewards.web.app/" className="linkjawn">
              <div className="rewardstab">
                <div className="icocirc">
                  <FontAwesomeIcon icon="user" className="rewardsico" /> 
                </div>
                <span>Rewards</span>
              </div>
            </a>
            <div className="orderbutton">Order Online</div>
          </Collapse>
        </Container>
      </Navbar>
      <style jsx>{`
        .navbar-expand-md {
          background-color: ${primaryColor} !important;
        }
        .orderbutton {
          background-color: ${secondaryColor} !important;
        }
      `}</style>
    </div>
  );
};

export default NavBar;
