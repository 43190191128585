import React, { Fragment, useState, useEffect } from "react";
import { doc, getDoc, writeBatch, collection, addDoc, getDocs } from "firebase/firestore";
import { db } from '../firebase';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import AdminAccordion from "../components/Accord";
import { useHistory } from 'react-router-dom';
import Slideshow from "../components/Slideshow";
import menuImage1 from "../assets/menu1.jpg";
import location from "../assets/location1.jpg";

const Edit = () => {
    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
      } = useAuth0();
   const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [clicked, setClicked] = useState(false);  

  const textAnimationStyle = {
    animation: isLoading ? 'bounce 0.5s infinite alternate' : 'none',
  };
  const dotAnimationStyle = {
    animation: isLoading ? 'blink 1s infinite' : 'none',
  };
  const progressBarAnimationStyle = {
    animation: isLoading ? 'progressBarAnimation 2s linear infinite' : 'none',
  };

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
  <Fragment>
    {isLoading ? (
      <div className="loading-screen">
        <h1 style={textAnimationStyle}>Loading...</h1>
      </div>
    ) : (

    <div className={`pigcontainer ${clicked ? 'transition' : ''}`}>
      <div className="featured">
        <div className="logoutb">
            <span onClick={() => logoutWithRedirect()}>
                Logout
            </span>
        </div>
        {/* <h2 className="headerdish">Website & Rewards Admin</h2> */}
        <div className="dishes" style={{marginTop: '20px'}}>
           <AdminAccordion />
            
        </div>
      </div>
    </div>
    )}
   
  </Fragment>
)};


export default withAuthenticationRequired(Edit, {
    onRedirecting: () => <Loading />,
  });
  
