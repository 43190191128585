import React, { useState, useEffect } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

function Slideshow(args) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [slides, setSlides] = useState([]);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');

  useEffect(() => {
    const siteData = sessionStorage.getItem("siteSettings");
    const slideData = sessionStorage.getItem("slides");
    if (siteData) {
      const siteSettings = JSON.parse(siteData);
      const slideos = JSON.parse(slideData);
      
      setPrimaryColor(siteSettings.primaryColor);
      setSecondaryColor(siteSettings.secondaryColor);
      document.documentElement.style.setProperty('--secondary-color', siteSettings.secondaryColor);
      setSlides(slideos);
      console.log('slideos', slideos);
    }
  }, []);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const renderedSlides = slides?.map((slide, index) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={index}
    >
      <img src={slide.src} alt={slide.altText} />
      <CarouselCaption
        style={{ backgroundColor: secondaryColor }} // Set background color dynamically
        captionText={slide.subcaption}
        captionHeader={slide.caption}
      />
    </CarouselItem>
  ));

  return (
    <div className="slideshow-container">
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        {...args}
      >
        <CarouselIndicators
          items={slides}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {renderedSlides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </div>
  );
}

export default Slideshow;
