import React, { Fragment, useState, useEffect } from "react";
import { doc, getDoc, writeBatch, collection, addDoc, getDocs } from "firebase/firestore";
import { db } from '../firebase';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from 'react-router-dom';
import Slideshow from "../components/Slideshow";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

const Events = () => {
   const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [clicked, setClicked] = useState(false);  
  const [fullMenuItems, setFullMenuItems] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const eventData = sessionStorage.getItem("events");
    
    if (eventData) {
      const siteSettings = JSON.parse(eventData);
      
      setFullMenuItems(siteSettings)
    //   console.log('slideos', slideos);
    }
  }, []);


  const textAnimationStyle = {
    animation: isLoading ? 'bounce 0.5s infinite alternate' : 'none',
  };
  const dotAnimationStyle = {
    animation: isLoading ? 'blink 1s infinite' : 'none',
  };
  const progressBarAnimationStyle = {
    animation: isLoading ? 'progressBarAnimation 2s linear infinite' : 'none',
  };

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }

  return (
  <Fragment>
    {isLoading ? (
      <div className="loading-screen">
        <h1 style={textAnimationStyle}>Loading...</h1>
      </div>
    ) : (

    <div className={`pigcontainer ${clicked ? 'transition' : ''}`}>
      <Slideshow />
      <div className="featured">
        <h2 className="headerdish">Events</h2>
        <div className="abouttext">
           <FullCalendar
                plugins={[dayGridPlugin]}
                initialView='dayGridMonth'
                weekends={true}
                events={fullMenuItems}
                eventContent={renderEventContent}
            />
        </div>
      </div>
    </div>
    )}
   
  </Fragment>
)};

export default Events;
