import React, { useState, useEffect } from 'react';
import { Collapse, Card, CardBody, Button } from 'reactstrap';
import InfoAccordion from "../views/InfoAccord";
import SliderAccord from "../components/SliderAccord";
import EventsAccord from "../components/EventAccord";
import MenuAccord from "../views/MenuAccord";

const AdminAccordion = () => {
  const [menuOpen, setMenuOpen] = useState(false); 
  const [sliderOpen, setSliderOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [eventsOpen, setEventsOpen] = useState(false);
  const [rewardsOpen, setRewardsOpen] = useState(false);
  const [locationsOpen, setLocationsOpen] = useState(false);
  const [slides, setSlides] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [eventItems, seteventItems] = useState([]);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');


useEffect(() => {
    const handleJawn = async () => {    
        // Load data from Firebase for slides
        const slideData = sessionStorage.getItem("slides");

        const slideos = JSON.parse(slideData);
        setSlides(slideos);

        const menudata = sessionStorage.getItem("menuItems");
        const menuitems = JSON.parse(menudata);
        setMenuItems(menuitems);
        
        const eventData = sessionStorage.getItem("events");
        const eventtems = JSON.parse(eventData);
        seteventItems(eventtems);


        
        // const slidesCollectionRef = collection(db, "slides");
        // const slidesSnapshot = await getDocs(slidesCollectionRef);
        // const slidesData = slidesSnapshot.docs.map((doc) => doc.data());
        // setSlides(slidesData);

    }
    handleJawn();

    const siteSettings = sessionStorage.getItem("siteSettings");
    if (siteSettings) {
      const settings = JSON.parse(siteSettings);
      setPrimaryColor(settings.primaryColor);
      setSecondaryColor(settings.secondaryColor);
    }
  }, []);

  const toggleAccordion = (accordionStateSetter, otherAccordions) => {
    // Close other accordions
    otherAccordions.forEach(setter => setter(false));
    // Toggle the current accordion
    accordionStateSetter(prevState => !prevState);
  };

  return (
    <div className='admin-cont'>
      <Button className="AccordionButton" style={menuOpen ? { backgroundColor: secondaryColor, color: "white" } : {}} onClick={() => toggleAccordion(setMenuOpen, [setSliderOpen, setInfoOpen, setEventsOpen, setRewardsOpen, setLocationsOpen])} >Menu</Button>
      <Collapse isOpen={menuOpen}>
        <Card>
          <CardBody>
            <MenuAccord menuItems={menuItems} />
          </CardBody>
        </Card>
      </Collapse>

      <Button className="AccordionButton" style={sliderOpen ? { backgroundColor: secondaryColor, color: "white" } : {}} onClick={() => toggleAccordion(setSliderOpen, [setMenuOpen, setInfoOpen, setEventsOpen, setRewardsOpen, setLocationsOpen])} >Slider</Button>
      <Collapse isOpen={sliderOpen}>
        <Card>
          <CardBody>
           <SliderAccord slides={slides} />
          </CardBody>
        </Card>
      </Collapse>

      <InfoAccordion isOpen={infoOpen} toggleAccordion={() => toggleAccordion(setInfoOpen, [setMenuOpen, setSliderOpen, setEventsOpen, setRewardsOpen])} />

      <Button className="AccordionButton" onClick={() => toggleAccordion(setEventsOpen, [setMenuOpen, setSliderOpen, setInfoOpen, setRewardsOpen])} >Events</Button>
      <Collapse isOpen={eventsOpen}>
        <Card>
          <CardBody>
            <EventsAccord events={eventItems} />
          </CardBody>
        </Card>
      </Collapse>

      <Button className="AccordionButton" onClick={() => toggleAccordion(setLocationsOpen, [setMenuOpen, setSliderOpen, setInfoOpen, setEventsOpen])} >Locations</Button>
      <Collapse isOpen={locationsOpen}>
        <Card>
          <CardBody>
            {/* Content for Rewards CRUD operations */}
          </CardBody>
        </Card>
      </Collapse>

      <Button className="AccordionButton" onClick={() => toggleAccordion(setRewardsOpen, [setMenuOpen, setSliderOpen, setInfoOpen, setEventsOpen])} >Rewards</Button>
      <Collapse isOpen={rewardsOpen}>
        <Card>
          <CardBody>
            {/* Content for Rewards CRUD operations */}
          </CardBody>
        </Card>
      </Collapse>

    </div>
  );
};

export default AdminAccordion;
