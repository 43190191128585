import React, { useEffect, useState } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Menu from "./views/Menu";
import Events from "./views/Events";
import About from "./views/About";
import Locations from "./views/Locations";
import Edit from "./views/Edit";
import Loading from "./components/Loading";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { isLoading: auth0Loading, error } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadDataFromFirebase = async () => {
      try {
        // Load data from Firebase for site settings
        const siteSettingsDocRef = doc(db, "settings", "site");
        const siteSettingsDoc = await getDoc(siteSettingsDocRef);
        const siteSettingsData = siteSettingsDoc.data();

         // Load data from Firebase for slides
          const slidesCollectionRef = collection(db, "slides");
          const slidesSnapshot = await getDocs(slidesCollectionRef);
          const slidesData = slidesSnapshot.docs.map((doc) => ({
            ...doc.data(), // Include other data from the document
            key: doc.id, // Use document ID as the key
          }));

          // Load data from Firebase for slides
          const menuCollectionRef = collection(db, "menu");
          const menuSnapshot = await getDocs(menuCollectionRef);
          const menuItemsData = menuSnapshot.docs.map((doc) => ({
            ...doc.data(), // Include other data from the document
            key: doc.id, // Use document ID as the key
            id: doc.id, // Use document ID as the key
          }));

           // Load data from Firebase for slides
           const eventsCollectionRef = collection(db, "events");
           const evenSnapShot = await getDocs(eventsCollectionRef);
           const eventsItemsData = evenSnapShot.docs.map((doc) => ({
             ...doc.data(), // Include other data from the document
             key: doc.id, // Use document ID as the key
             id: doc.id, // Use document ID as the key
           }));
 

        
         console.log('menuItemsData ', menuItemsData);
         console.log('eventsItemsData ', eventsItemsData);
        // Store only site settings data in sessionStorage
        sessionStorage.setItem("siteSettings", JSON.stringify(siteSettingsData));
        sessionStorage.setItem("slides", JSON.stringify(slidesData));
        sessionStorage.setItem("menuItems", JSON.stringify(menuItemsData));
        sessionStorage.setItem("events", JSON.stringify(eventsItemsData));
  
        console.log('siteSettingsData ', siteSettingsData);
  
        setIsLoading(false);
      } catch (error) {
        console.error("Error loading data from Firebase:", error);
        setIsLoading(false); // Ensure loading state is set to false even on error
      }
    };
    console.log("STEVE")
    // Call the function to load data from Firebase
    loadDataFromFirebase();
  }, []);
  
  

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (auth0Loading || isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route path="/menu" component={Menu} />
            <Route path="/about" component={About} />
            <Route path="/events" component={Events} />
            <Route path="/locations" component={Locations} />
            <Route path="/edit" component={Edit} />
            <Route path="/" component={Home} />
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
