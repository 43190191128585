import React, { useState, useEffect } from "react";

const Footer = () => {
  const [siteName, setSiteName] = useState(false);
  const [primaryColor, setPrimaryColor] = useState('');

  useEffect(() => {
    const siteSettings = sessionStorage.getItem("siteSettings");
    if (siteSettings) {
      const settings = JSON.parse(siteSettings);
      setSiteName(settings.siteName);
      setPrimaryColor(settings.primaryColor);
    }
  }, []);
  
  

  return (


    <footer className="bg-light p-3 text-center footersl">
      {/* <div className="logo" /> */}
      <p className="fullwidth">
        <span>®2024 {siteName}</span>
        <span> - Powered by <a href="https://dudesgood.com/" rel="noopener noreferrer" target="_blank" style={{ color: primaryColor, textDecoration: 'none' }}>DG</a>.</span>
        {/* <span className="footerstu"><a href="#">Terms</a> | <a href="#">Privacy Policy</a></span> */}
      </p>
    </footer>
  );
};

export default Footer;
