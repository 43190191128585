import React from 'react';
import FileUpload from '../components/FileUpload';

const MenuCard = ({ index, menuItem, handleEdit, onDelete, handleSaveEdit, handleCancelEdit, handleFileChange, imageFile, handleInputChange }) => {
  return (
    <div className="menu-card">
      <div className="menu-card-header">
        <div className="menu-card-title dishtitle">{menuItem.name}</div>
      </div>
      <div className="menu-card-body">
        <div className='menu-image-holder'>
          <img style={{    maxHeight: '250px'}} src={menuItem.image} alt={menuItem.name} />
        </div>
        {!menuItem.editMode &&
          <div className="menu-card-details">
            <p className='mendesc'>{menuItem.description}</p>
            <p className='menu-cat'>{menuItem.category}</p>
            <p className='price-men'>${menuItem.price}</p>
            {menuItem.featured  && <p>Featured</p>}
          </div>
        }
        {menuItem.editMode && (
          <div className='formhold'>
            <input type="text" value={menuItem.name} onChange={(e) => handleInputChange(e, 'name')} />
            <input type="text" value={menuItem.description} onChange={(e) => handleInputChange(e, 'description')} />
            <input type="text" value={menuItem.price} onChange={(e) => handleInputChange(e, 'price')} />
            <div className='catfield'>
              <select style={{    width: "100%"}} value={menuItem.category} onChange={(e) => handleInputChange(e, 'category')}>
                <option value="">Select Category</option>
                <option value="Beverages">Beverages</option>
                <option value="Desserts">Desserts</option>
                <option value="Entrees">Entrees</option>
                <option value="Appetizers">Appetizers</option>
                <option value="Drinks">Drinks</option>
                <option value="Kids">Kids</option>
              </select>
            </div>
            <div className='checkbox'>
              <label style={{paddingRight: '12px'}}>Is Featured?</label>
              <input type="checkbox" checked={menuItem.featured} onChange={(e) => handleInputChange(e, 'featured')} />
            </div>
            <img src={imageFile || menuItem.image} alt="Item" style={{ width: '100px', marginRight: '10px', height: 'auto' }} />
            <FileUpload onUploadSuccess={handleFileChange} />
            <button onClick={() => handleSaveEdit(index)}>Save</button>
            <button onClick={() => handleCancelEdit(index)}>Cancel</button>
          </div>
        )}
      </div>
      {!menuItem.editMode && <div className="menu-card-actions">
          <button className='editbut' onClick={() => handleEdit(index)}>Edit</button>
          <button className='delbut' onClick={() => onDelete(menuItem.id)}>Delete</button>
        </div>}
    </div>
  );
};

export default MenuCard;
