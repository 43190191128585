import React, { useState, useEffect } from 'react';
import { doc, updateDoc, deleteDoc, addDoc, collection } from "firebase/firestore";
import FileUpload from '../components/FileUpload';
import { storage, ref, uploadBytes, getDownloadURL, db } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'react-toastify/dist/ReactToastify.css';
import MenuCard from './MenuCard';

const MenuAccord = ({ menuItems }) => {
    const [menuItemTable, setMenuItemTable] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [showAddForm, setShowAddForm] = useState(false);
    const [newMenuItemData, setNewMenuItemData] = useState({ name: '', description: '', price: '', category: '', featured: false });
    const [file, setFile] = useState(null);

    useEffect(() => {
        if (menuItems.length > 0) {
            setMenuItemTable(menuItems.map(item => ({ ...item, editMode: false })));
        }
    }, [menuItems]);

    const handleEdit = (index) => {
        const updatedMenuItems = [...menuItemTable];
        updatedMenuItems[index].editMode = true;
        setMenuItemTable(updatedMenuItems);
    };

    const onDelete = async (id) => {
        try {
          if (!Array.isArray(menuItemTable)) {
            console.error('Error deleting menu item: menuItemTable is not an array');
            return;
          }
      
          const index = menuItemTable.findIndex(item => item.id === id);
          if (index === -1) {
            console.error('Error deleting menu item: Menu item not found');
            return;
          }
      
          await deleteDoc(doc(db, 'menu', id));
          const updatedMenuItems = menuItemTable.filter(item => item.id !== id);
          setMenuItemTable(updatedMenuItems);
          toast.success('SUCCESS: Menu item deleted!', {
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } catch (error) {
          console.error('Error deleting menu item:', error);
          toast.error('Error deleting menu item. Please try again.', {
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      };
      
      

      const handleSaveEdit = async (index) => {
        try {
            let downloadURL = null;
            let storageRef;
            let snapshot;
            if (file) {
                storageRef = ref(storage, 'menu/' + file.name);
                snapshot = await uploadBytes(storageRef, file);
                downloadURL = await getDownloadURL(snapshot.ref);
            }
    
            const updatedMenuItems = menuItemTable.map((item, idx) => {
                if (idx === index) {
                    return { ...item, image: downloadURL ? downloadURL : menuItemTable[index].image, editMode: false };
                }
                return item;
            });
    
            await updateDoc(doc(db, 'menu', menuItemTable[index].id), updatedMenuItems[index]);
            setMenuItemTable(updatedMenuItems);
            setImageUrl(null);
    
            toast.success('SUCCESS: Menu item updated!', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.error('Error saving edit:', error);
        }
    };
    
    // const handleApiCall = async (imageUrl) => {
    //     setImageUrl(imageUrl);
    //     setImageFile(imageUrl);
    //     toast.success('SUCCESS: Image uploaded! Ready to Save.', {
    //         position: 'top-right',
    //         autoClose: 1500,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //     });
    // };

    const handleAddMenuItem = async () => {
        setShowAddForm(true);
    };

    const handleCancelAdd = () => {
        setShowAddForm(false);
        setNewMenuItemData({ name: '', description: '', price: '', category: '', featured: false });
    };

    const handleSaveAdd = async () => {
        try {
            if (!file) {
                console.error('Error saving new menu item: No file selected');
                return;
            }
            const storageRef = ref(storage, 'menu/' + file.name);
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            const newMenuItem = { ...newMenuItemData, image: downloadURL };
            const docRef = await addDoc(collection(db, 'menu'), newMenuItem);
            const updatedMenuItems = [...menuItemTable, { ...newMenuItem, id: docRef.id, editMode: false }];
            setMenuItemTable(updatedMenuItems);
            setShowAddForm(false);
            setNewMenuItemData({ name: '', description: '', price: '', category: '', featured: false });
            setImageUrl(null);
            toast.success('SUCCESS: New menu item added!', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.error('Error saving new menu item:', error);
        }
    };

    const handleInputChange = (e, field, index) => {
        const { value } = e.target;
        const updatedMenuItems = [...menuItemTable];
        updatedMenuItems[index][field] = value;
        setMenuItemTable(updatedMenuItems);
      };

      
    const handleCancelEdit = (index) => {
        const updatedMenuItems = [...menuItemTable];
        updatedMenuItems[index].editMode = false;
        setMenuItemTable(updatedMenuItems);
        setImageUrl(null);
    };

    const handleFileChange = (file) => {
        setFile(file);
        console.log('file set', file)
        setNewMenuItemData({ ...newMenuItemData, image: file });
    };

    return (
      <>
      <button style={{ marginBottom: '20px', float: 'right' }} onClick={handleAddMenuItem}><FontAwesomeIcon icon="plus-circle" className="rewardsico" /> Add Menu Item</button>
        <div className='menucomp'>
      {showAddForm && (
        <div className='newform'>
          <input
            type="text"
            value={newMenuItemData.name}
            onChange={(e) => setNewMenuItemData({ ...newMenuItemData, name: e.target.value })}
            placeholder="Enter Name"
          />
          <input
            type="text"
            value={newMenuItemData.description}
            onChange={(e) => setNewMenuItemData({ ...newMenuItemData, description: e.target.value })}
            placeholder="Enter Description"
          />
          <input
            type="text"
            value={newMenuItemData.price}
            onChange={(e) => setNewMenuItemData({ ...newMenuItemData, price: e.target.value })}
            placeholder="Enter Price"
          />
          <div className='catfield'>
            <span>Category</span>
            <select
              value={newMenuItemData.category}
              onChange={(e) => setNewMenuItemData({ ...newMenuItemData, category: e.target.value })}
            >
              <option value="">Select Category</option>
              <option value="Beverages">Beverages</option>
              <option value="Desserts">Desserts</option>
              <option value="Entrees">Entrees</option>
              <option value="Appetizers">Appetizers</option>
              <option value="Drinks">Drinks</option>
              <option value="Kids">Kids</option>
            </select>
          </div>
          <div className='catfield'>
            <span>Is Featured?</span>
            <input
              type="checkbox"
              checked={newMenuItemData.featured}
              onChange={(e) => setNewMenuItemData({ ...newMenuItemData, featured: e.target.checked })}
            />
          </div>
          <FileUpload onUploadSuccess={handleFileChange} />
          <button onClick={handleSaveAdd}>Save</button>
          <button onClick={handleCancelAdd}>Cancel</button>
        </div>
      )}
      {menuItemTable.map((menuItem, index) => (
            <MenuCard
                key={index}
                index={index} // Pass the index prop here
                menuItem={menuItem}
                handleEdit={handleEdit}
                onDelete={onDelete}
                handleSaveEdit={handleSaveEdit}
                handleCancelEdit={handleCancelEdit}
                handleFileChange={handleFileChange}
                imageFile={imageFile}
                handleInputChange={(e, field) => handleInputChange(e, field, index)}
            />
            ))}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
    </>
    );
};

export default MenuAccord;
