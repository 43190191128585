import React, { useState, useEffect } from 'react';

function FeaturedItem() {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const handleJawn = async () => {    
        // Load data from Firebase for slides
        const menudata = sessionStorage.getItem("menuItems");

        const menuitems = JSON.parse(menudata);
        setMenuItems(menuitems);

    }
    handleJawn();
  }, []);

  return (
      <div className="featured">
          <h2 className="headerdish">Featured Dishes</h2>
          <div className="dishes">
              {menuItems
                  .filter(menuItem => menuItem.featured === true)
                  .map((menuItem, index) => (
                      <div key={index} className="dish" style={{ backgroundImage: `url("${menuItem.image}")` }}>
                          <div className="dishdetails">
                              <p className="dishtitle">{menuItem.name}</p>
                              <p className="dishdescription">{menuItem.description}</p>
                          </div>
                      </div>
                  ))}
          </div>
      </div>
  );
}

export default FeaturedItem;
