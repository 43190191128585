import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Slideshow from "../components/Slideshow";
import FeaturedItem from "../components/FeaturedItem";
import { Link } from "react-router-dom";

const Home = () => {
   const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [aboutUsText, setAboutUsText] = useState(false);  
  const [rewardsText, setRewardsText] = useState(false);  
  const [siteName, setSiteName] = useState(false);  

  const textAnimationStyle = {
    animation: isLoading ? 'bounce 0.5s infinite alternate' : 'none',
  };
  const dotAnimationStyle = {
    animation: isLoading ? 'blink 1s infinite' : 'none',
  };
  const progressBarAnimationStyle = {
    animation: isLoading ? 'progressBarAnimation 2s linear infinite' : 'none',
  };

  const truncatedText = aboutUsText.length > 350 ? aboutUsText.slice(0, 350) + "..." : aboutUsText;


  useEffect(() => {
    const siteSettings = sessionStorage.getItem("siteSettings");
    if (siteSettings) {
      const settings = JSON.parse(siteSettings);
      setAboutUsText(settings.aboutText);
      setRewardsText(settings.rewardsText);
      setSiteName(settings.siteName);
    }
  }, []);

  return (
  <Fragment>
    {isLoading ? (
      <div className="loading-screen">
        <h1 style={textAnimationStyle}>Loading...</h1>
      </div>
    ) : (

    <div className={`pigcontainer`}>
      <Slideshow />
      <div className="abouttext expad">
      <p dangerouslySetInnerHTML={{ __html: truncatedText }} />
      <Link to="/about">Read more about {siteName}.</Link>

         
        </div>
        <hr className="rule" />
        <FeaturedItem />

        <hr className="rule" />
        <div className="featured">
        <h2 className="headerdish expad-t">Earn {siteName} Rewards!</h2>
        <div className="abouttext expad">
         <p dangerouslySetInnerHTML={{ __html: rewardsText }} />
         
         <Link to="/about">Join {siteName} Rewards.</Link>
         
        </div>
        </div>
      </div>
    )}
   
  </Fragment>
)};

export default Home;
