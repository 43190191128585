import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Slideshow from "../components/Slideshow";
import FeaturedItem from "../components/FeaturedItem";

const Menu = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [fullMenuItems, setFullMenuItems] = useState([]);

  useEffect(() => {
    const handleJawn = async () => {
      // Load data from Firebase for slides
      const menudata = sessionStorage.getItem("menuItems");
      const menuitems = JSON.parse(menudata);

      const groupedMenuItems = menuitems.reduce((acc, currentItem) => {
        const category = currentItem.category;
        // Check if the category already exists in the accumulator
        if (acc[category]) {
          // If it exists, push the current item into the items array of that category
          acc[category].items.push(currentItem);
        } else {
          // If it doesn't exist, create a new category object with the current item
          acc[category] = {
            category: category,
            items: [currentItem]
          };
        }
        return acc;
      }, {});

      // Convert the object of grouped menu items into an array of category objects
      const groupedMenuItemsArray = Object.values(groupedMenuItems);

      // Now, groupedMenuItemsArray contains an array of category objects
      console.log(groupedMenuItemsArray);
      setFullMenuItems(groupedMenuItemsArray);
    };

    handleJawn();
  }, []);

  const textAnimationStyle = {
    animation: isLoading ? 'bounce 0.5s infinite alternate' : 'none',
  };
  const dotAnimationStyle = {
    animation: isLoading ? 'blink 1s infinite' : 'none',
  };
  const progressBarAnimationStyle = {
    animation: isLoading ? 'progressBarAnimation 2s linear infinite' : 'none',
  };

  return (
    <Fragment>
      {isLoading ? (
        <div className="loading-screen">
          <h1 style={{ animation: isLoading.toString() ? 'bounce 0.5s infinite alternate' : 'none' }}>Loading...</h1>
        </div>
      ) : (

        <div className={`pigcontainer ${clicked ? 'transition' : ''}`}>
          <Slideshow />
          <FeaturedItem />
          <div className="menu">
            {fullMenuItems.map((category, index) => (
                <div key={index} className="featured">
                    <h2 className="headerdish">{category.category}</h2>
                    <div className="dishes">
                        {category.items.map((menuItem, itemIndex) => (
                            <div key={itemIndex} className="dish" style={{ backgroundImage: `url("${menuItem.image}")` }}>
                                <div className="dishdetails">
                                    <p className="dishtitle">{menuItem.name}</p>
                                    <p className="dishdescription">{menuItem.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
        </div>
      )}

    </Fragment>
  )
};

export default Menu;
