import React, { Fragment, useState, useEffect } from "react";
import { doc, getDoc, writeBatch, collection, addDoc, getDocs } from "firebase/firestore";
import { db } from '../firebase';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from 'react-router-dom';
import Slideshow from "../components/Slideshow";
import menuImage1 from "../assets/menu1.jpg";
import menuImage2 from "../assets/menu2.jpg";

const About = () => {
   const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [clicked, setClicked] = useState(false);  
  const [aboutUsText, setAboutUsText] = useState(false);  
  const [primaryColor, setPrimaryColor] = useState('');
  const [aboutUsImage, setAboutUsImage] = useState(false);  

  const textAnimationStyle = {
    animation: isLoading ? 'bounce 0.5s infinite alternate' : 'none',
  };
  const dotAnimationStyle = {
    animation: isLoading ? 'blink 1s infinite' : 'none',
  };
  const progressBarAnimationStyle = {
    animation: isLoading ? 'progressBarAnimation 2s linear infinite' : 'none',
  };

  useEffect(() => {
    const siteSettings = sessionStorage.getItem("siteSettings");
    if (siteSettings) {
      const settings = JSON.parse(siteSettings);
      console.log('settings ', settings.aboutText)
      setAboutUsText(settings.aboutText);
      setAboutUsImage(settings.aboutImage);
      setPrimaryColor(settings.primaryColor);
    }
  }, []);

  return (
  <Fragment>
    {isLoading ? (
      <div className="loading-screen">
        <h1 style={textAnimationStyle}>Loading...</h1>
      </div>
    ) : (

    <div className={`pigcontainer ${clicked ? 'transition' : ''}`}>
      <Slideshow />
      <div className="featured">
        <h2 className="headerdish">About the Taco Shop</h2>
        <div className="abouttext">
            <img src={aboutUsImage} className="aboutusimage" style={{border: `2px solid ${primaryColor}`}} />
            <p dangerouslySetInnerHTML={{ __html: aboutUsText }} />
        </div>
      </div>
    </div>
    )}
   
  </Fragment>
)};

export default About;
