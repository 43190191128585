import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlusCircle, faLink, faUndo, faPowerOff, faUser, faArrowCircleLeft, faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";

function initFontAwesome() {
  library.add(faPlusCircle);
  library.add(faLink);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faArrowCircleDown);
  library.add(faArrowCircleLeft);
  library.add(faUndo);
}

export default initFontAwesome;
