import React, { Fragment, useState, useEffect } from "react";
import { doc, getDoc, writeBatch, collection, addDoc, getDocs } from "firebase/firestore";
import { db } from '../firebase';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from 'react-router-dom';
import Slideshow from "../components/Slideshow";
import menuImage1 from "../assets/menu1.jpg";
import location from "../assets/location1.jpg";

const Locations = () => {
   const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [clicked, setClicked] = useState(false);  

  const textAnimationStyle = {
    animation: isLoading ? 'bounce 0.5s infinite alternate' : 'none',
  };
  const dotAnimationStyle = {
    animation: isLoading ? 'blink 1s infinite' : 'none',
  };
  const progressBarAnimationStyle = {
    animation: isLoading ? 'progressBarAnimation 2s linear infinite' : 'none',
  };

  return (
  <Fragment>
    {isLoading ? (
      <div className="loading-screen">
        <h1 style={textAnimationStyle}>Loading...</h1>
      </div>
    ) : (

    <div className={`pigcontainer ${clicked ? 'transition' : ''}`}>
      <Slideshow />
      <div className="featured">
        <h2 className="headerdish">Locations</h2>
        <div className="dishes">
            <div className="location" style={{backgroundImage: 'url("https://taco-shop-website.web.app/static/media/location1.585c9835771c29908722.jpg")'}}>
                <div className="dishdetails">
                    <p className="dishtitle">Miami Lakes</p>
                    <p className="dishdescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nunc felis, pretium a odio sit amet, placerat pulvinar turpis.</p>
                    <div className="address">
                        <strong>Red Palmetto Shoppes</strong> <br/>
                        <span>16010 NW 57th Ave <br /> Suite 138, <br /> Miami Lakes, FL 33014</span>
                        <br /><i>Tel: (786) 464-0448</i>
                    </div>
                </div>
            </div>
            
        </div>
      </div>
    </div>
    )}
   
  </Fragment>
)};

export default Locations;
