import React, { useState, useEffect } from 'react';
import { doc, updateDoc, deleteDoc, addDoc, collection } from "firebase/firestore";
import FileUpload from '../components/FileUpload';
import { storage, ref, uploadBytes, getDownloadURL, db } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'react-toastify/dist/ReactToastify.css';

const SliderAccord = ({ slides }) => {
  const [newSlideTable, setNewSlideTable] = useState([]);
  const [editSlide, setEditSlide] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newSlideData, setNewSlideData] = useState({ caption: '', subcaption: '' });
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (slides.length > 0) {
        console.log('SLIDES ', slides)
        setNewSlideTable(slides)
    //   setSlideTable(slides)
    }
  }, [slides]);

  const handleEdit = (slide) => {
    setEditSlide({ ...slide });
    setImageFile(null);
  };

  const handleFileChange = (file) => {
    setFile(file);
    console.log('file set', file);
    // setImageFile(file); // Remove this line
};

  const onDelete = async (key) => {
    try {
      await deleteDoc(doc(db, 'slides', key));
      const updatedSlides = newSlideTable.filter(slide => slide.key !== key);
      setNewSlideTable(updatedSlides);
      toast.success('SUCCESS: Slide deleted!', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error('Error deleting slide:', error);
      toast.error('Error deleting slide. Please try again.', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleSaveEdit = async () => {
    try {
        if (!file) {
            console.error('Error saving new slide item: No file selected');
            return;
        }

        const storageRef = ref(storage, 'slides/' + file.name);
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);

        const updatedSlide = { ...editSlide, src: downloadURL };

        await updateDoc(doc(db, 'slides', editSlide.key), updatedSlide);

        const updatedSlides = newSlideTable.map((slide) => {
            if (slide.key === editSlide.key) {
                return updatedSlide;
            }
            return slide;
        });

        setNewSlideTable(updatedSlides);
        setImageUrl(null);
        toast.success('SUCCESS: Slider updated!', {
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setEditSlide(null);
    } catch (error) {
        console.error('Error saving edit:', error);
    }
};

//   const handleApiCall = async (imageUrl) => {
//     setImageUrl(imageUrl)
//     setImageFile(imageUrl)
//     toast.success('SUCCESS: Image uploaded! Ready to Save.', {
//       position: 'top-right',
//       autoClose: 1500,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//     });
//   };

  const handleAddSlide = async () => {
    setShowAddForm(true);
  };

  const handleCancelAdd = () => {
    setShowAddForm(false);
    setNewSlideData({ caption: '', subcaption: '' });
  };

  const handleSaveAdd = async () => {
    try {
        if (!file) {
            console.error('Error saving new slide item: No file selected');
            return;
        }
        const storageRef = ref(storage, 'menu/' + file.name);
        await uploadBytes(storageRef, file).then(async snapshot => {
            const downloadURL = await getDownloadURL(snapshot.ref);
            const newSlide = { ...newSlideData, src: downloadURL }; // Remove the image field
            const docRef = await addDoc(collection(db, 'slides'), newSlide);
            const updatedSlides = [...newSlideTable, { ...newSlide, key: docRef.id }];
            setNewSlideTable(updatedSlides);
            setShowAddForm(false);
            setNewSlideData({ caption: '', subcaption: '' });
            setImageUrl(null);
            setEditSlide(null);
            toast.success('SUCCESS: New slide added!', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    } catch (error) {
        console.error('Error saving new slide:', error);
    }
};




  const handleCancelEdit = () => {
    setEditSlide(null);
    setImageUrl(null);
  };
  

  return (
    <div className='slidercomp'>
      <button style={{ marginBottom: '20px', float: 'right'}}onClick={handleAddSlide}><FontAwesomeIcon icon="plus-circle" className="rewardsico" /> Add Slide</button>
      {showAddForm && (
        <div className='newform'>
          <input
            type="text"
            value={newSlideData.caption}
            onChange={(e) => setNewSlideData({ ...newSlideData, caption: e.target.value })}
            placeholder="Enter Caption"
          />
          <input
            type="text"
            value={newSlideData.subcaption}
            onChange={(e) => setNewSlideData({ ...newSlideData, subcaption: e.target.value })}
            placeholder="Enter Subcaption"
          />
          <FileUpload onUploadSuccess={handleFileChange} />
          <span className='captiona'>Optimal Size: 1756 px × 619 px</span>
          <button onClick={handleSaveAdd}>Save</button>
          <button onClick={handleCancelAdd}>Cancel</button>
        </div>
      )}
        <table className="table">
        <thead>
          <tr>
            <th>Caption</th>
            <th>Subcaption</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {newSlideTable.map((slide, index) => (
            <tr key={index}>
              <td>{editSlide && editSlide.key === slide.key ? (
                <input type="text" value={editSlide.caption} onChange={(e) => setEditSlide({ ...editSlide, caption: e.target.value })} />
              ) : slide.caption}</td>
              <td>{editSlide && editSlide.key === slide.key ? (
                <input type="text" value={editSlide.subcaption} onChange={(e) => setEditSlide({ ...editSlide, subcaption: e.target.value })} />
              ) : slide.subcaption}</td>
             <td>
                {editSlide && editSlide.key === slide.key ? (
                    <>
                    {imageFile ? (
                        <img src={imageFile} alt="New Slide" style={{ width: '100px', marginRight: '10px', height: 'auto' }} />
                    ) : (
                        <img src={editSlide.src} alt={editSlide.altText} style={{ width: '100px', marginRight: '10px', height: 'auto' }} />
                    )}
                        
                        <FileUpload onUploadSuccess={handleFileChange} />
                        <span className='captiona'>Optimal Size: 1756 px × 619 px</span>
                    </>
                ) : (
                    <img src={slide.src} alt={slide.altText} style={{ width: '100px', marginRight: '10px', height: 'auto' }} />
                )}
            </td>

              <td>
                {editSlide && editSlide.key === slide.key ? (
                  <>
                    <button onClick={handleSaveEdit}>Save</button>
                    <button onClick={handleCancelEdit}>Cancel</button>
                  </>
                ) : (
                  <>
                    <button onClick={() => handleEdit(slide)}>Edit</button>
                    <button onClick={() => onDelete(slide.key)}>Delete</button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default SliderAccord;
