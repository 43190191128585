import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCwNOxX4c6vLpEVm_7haESZuiNLLDhdE8c",
  authDomain: "taco-shop-website.firebaseapp.com",
  projectId: "taco-shop-website",
  storageBucket: "taco-shop-website.appspot.com",
  messagingSenderId: "1063614000424",
  appId: "1:1063614000424:web:2ad171dac99c1a4b01b41d",
  measurementId: "G-W4D83BFQCC"
};

const rewardfirebaseConfig = {
  apiKey: "AIzaSyD-oMsfDBuQ0JAZqZftUoXt5I9FPsSJ1I0",
  authDomain: "taco-shop-rewards.firebaseapp.com",
  projectId: "taco-shop-rewards",
  storageBucket: "taco-shop-rewards.appspot.com",
  messagingSenderId: "372394227373",
  appId: "1:372394227373:web:c44db864ef0c3b94383824",
  measurementId: "G-X5HDQQ8J5Q"
};

// Initialize Firebase and Firestore for the main app
const app = initializeApp(firebaseConfig, 'main');
const db = getFirestore(app);
const storage = getStorage(app); // For Firebase Storage

// Initialize Firebase and Firestore for the rewards app
const rewardsapp = initializeApp(rewardfirebaseConfig, 'rewards');
const rewardsdb = getFirestore(rewardsapp);
const rewardsstorage = getStorage(rewardsapp); // For Firebase Storage

export { db, storage, rewardsdb, rewardsstorage, ref, uploadBytes, getDownloadURL };
